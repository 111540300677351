import React from "react";
import {Text} from "react-native";
import {StyleSheet, TextStyle} from "react-native";
import {filterA11yRoleByPlatform} from "./a11y";
import Colors from "../colors";
import {TypographyBaseProps} from "./types";

type HeadingProps = TypographyBaseProps & {
  level: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};

export default function Heading({
  accessibilityLevel: overrideaccessibilityLevel,
  accessibilityRole = "header",
  children,
  level,
  style,
  testID,
  weight = "normal",
}: HeadingProps) {
  // use the accessibilityLevel prop or the default for the given level
  // level 0 defaults to a heading level 1
  const accessibilityLevel = overrideaccessibilityLevel || level || 1;

  return (
    <Text
      accessibilityLevel={
        // only headings and lists can have a level https://www.w3.org/TR/wai-aria-1.2/#aria-level
        accessibilityRole === "header" || accessibilityRole === "listitem"
          ? accessibilityLevel
          : undefined
      }
      accessibilityRole={filterA11yRoleByPlatform(accessibilityRole)}
      style={[headingStyles[level], headingStyles[weight], style]}
      testID={testID}
    >
      {children}
    </Text>
  );
}

const baseHeadingStyles: TextStyle = {
  color: Colors.gray[800],
};

export const headingStyles = StyleSheet.create({
  bold: {
    fontFamily: "Inter-semibold",
  },
  light: {
    fontFamily: "Inter-light",
  },
  normal: {
    fontFamily: "Inter-regular",
  },
  0: {
    ...baseHeadingStyles,
    fontSize: 72,
    lineHeight: 82,
  },
  1: {
    ...baseHeadingStyles,
    fontSize: 60,
    lineHeight: 68,
  },
  2: {
    ...baseHeadingStyles,
    fontSize: 48,
    lineHeight: 56,
  },
  3: {
    ...baseHeadingStyles,
    fontSize: 40,
    lineHeight: 48,
  },
  4: {
    ...baseHeadingStyles,
    fontSize: 32,
    lineHeight: 40,
  },
  5: {
    ...baseHeadingStyles,
    fontSize: 28,
    lineHeight: 34,
  },
  6: {
    ...baseHeadingStyles,
    fontSize: 24,
    lineHeight: 32,
  },
});
