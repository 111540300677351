/**
 *
 * this is not ideal, but right now tailwind does not support non cjs configs
 * meaning we can't use the export default syntax of ESM. We want this library to be
 * ESM only so that we can start to use modern tooling like vite and swc. Any changes
 * to this or the colors.ts file should be mirrored with each other
 *
 */

module.exports = {
  green: {
    lighter: "#B8F2C2",
    lighter_40: "rgba(184, 242, 194, 0.4)",
    lighter_50: "rgba(184, 242, 194, 0.5)",
    light: "#6BD9A1",
    DEFAULT: "#24A06D",
    dark: "#158562",
    darker: "#0B5C53",
  },
  mint: {
    lighter: "#E0FCE5",
    light: "#BCECCF",
    DEFAULT: "#A3E1C2",
    dark: "#77C1A3",
    darker: "#338272",
  },
  red: {
    lighter: "#FCD1A1",
    light: "#EC8E4E",
    DEFAULT: "#E15C18",
    dark: "#C14111",
    darker: "#821907",
  },
  orange: {
    light: "#E09361",
    DEFAULT: "#E15C18",
  },
  purple: {
    lighter: "#F7E4F5",
    light: "#D6C0D8",
    DEFAULT: "#BAA3BF",
    dark: "#9877A4",
    darker: "#56336E",
  },
  yellow: {
    lighter: "#F3ECBB",
    light: "#F3D95A",
    DEFAULT: "#EBC627",
    dark: "#CAA51C",
    darker: "#88690C",
  },
  blue: {
    lighter: "#9EEAEF",
    light: "#80D3DF",
    DEFAULT: "#58B3CA",
    dark: "#58B3CA",
    darker: "#1c4f75",
    darkerRoyal: "#3680EF",
  },
  gray: {
    800: "#19191B",
    "800_60": "rgba(25, 25, 27, 0.6)",
    "800_50": "rgba(25, 25, 27, 0.5)",
    600: "#404042",
    400: "#6E7071",
    200: "#A5A5A5",
    "100_50": "rgba(230, 232, 235, 0.5)",
    100: "#E6E8EB",
    50: "#F9F9F9",
  },
  white: {
    DEFAULT: "#FFFFFF",
  },
  black: {
    DEFAULT: "#000000",
  },
  "modal-opacity": "rgba(0, 0, 0, 0.4)",
};
